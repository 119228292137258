@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --text-primary: #22abe2;
    --text-secondary: #faa0c4;
    --text-black: #000;
    --text-red: #ff0000;
    --text-color-white: #fff;
    --text-gray: #e7e9e3;
    --text-dark-gray: #909090;

    --background-color: #22abe2;
    --background-secondary: #faa0c4;
    --background-color-white: #fff;
    --background-color-gray: #f1f1f1;
    --background-color-lightgray: #fbf9f9;

    --border-color: #22abe2;
    --border-secondary: #faa0c4;
    --border-light: #e7e9e3;

    --radio-color: #22abe2;

    --hover-background-color: #faa0c4;
    --hover-text-color: #faa0c4;
    --hover-border-color: #faa0c4;
    --hover-btntext-color: #fff;
  }
}

.btn-link:hover {
  color: var(--hover-btntext-color);
  background-color: var(--hover-background-color);
  border-color: var(--hover-border-color);
}

.link:hover {
  color: var(--hover-text-color);
}

[type="checkbox"]:focus,
[type="radio"]:focus {
  outline: 0;
  outline-offset: 0;
  box-shadow: none;
  --tw-ring-offset-width: 0;
}

body {
  margin: 0;
  height: 100%;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

main {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: none;
  border: 1.5px solid #d1d5db;
}

button {
  outline: none;
}

.cus_svg {
  fill: #fff;
}

.dark .cus_svg {
  fill: #000;
}

.jobdetail {
  /* -webkit-transition: all 3s; */
  transition: 3s ease 0.3s;
}

.MuiInput-underline:before {
  border-bottom: 0 !important;
}

.MuiInput-underline:after {
  border-bottom: 0 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0 !important;
}

.MuiTextField-root {
  width: 100%;
}

.datatable .MuiTextField-root {
  width: auto;
}

.dark .datatable .MuiPaper-root {
  color: #fff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #262626;
}

.dark .datatable .MuiIconButton-root.Mui-disabled {
  color: #fff;
  background-color: transparent;
}

.dark .datatable .MuiTableCell-root {
  background-color: #262626;
  color: #fff;
}

.dark .datatable .MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #fff;
}

.dark .datatable .MuiTableSortLabel-root:hover {
  color: #fff;
}

.dark
  .datatable
  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: #fff;
  opacity: 1;
}

.dark .datatable .MuiToolbar-gutters {
  color: #fff !important;
  background-color: transparent;
}

.datatable .MuiToolbar-gutters {
  color: #000 !important;
  background-color: transparent;
}

.dark .datatable .MuiTableCell-root {
  border-bottom: 1px solid rgb(56 56 56);
}

.datatable .MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--background-secondary) !important;
}

.dark .MuiTablePagination-toolbar {
  background-color: #262626 !important;
}

.dark .datatable span.MuiTypography-root.MuiTypography-caption {
  color: #fff;
}

.dark .datatable .MuiIconButton-root {
  color: #fff;
}

.dark .MuiInput-formControl {
  border: 1.5px solid #00ff00;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  padding: 4px 10px;
}

.MuiInput-formControl {
  border: 1.5px solid #d1d5db;
  border-radius: 8px;
  background-color: #fff;
  color: #fff;
  padding: 4px 10px;
}

.MuiInputBase-input:focus {
  border: none !important;
}

.MuiInputBase-input {
  color: #000 !important;
}

.dark .MuiInputBase-input {
  color: #fff !important;
}

.dark .MuiSlider-root {
  color: #00ff00 !important;
}

.MuiSlider-root {
  color: #000 !important;
}

.dark .tagify__input {
  color: #fff !important;
}

.dark .tagify {
  width: 100%;
  border: 1.5px solid #00ff00 !important;
  border-radius: 8px;
}

.dark .tagify__tag.tagify--noAnim > div::before {
  animation: none;
  background-color: #00ff00 !important;
  color: #000;
  box-shadow: 0 0 0;
}

.dark .tagify__tag > div::before {
  animation: none;
  background-color: #00ff00 !important;
  color: #000;
  box-shadow: 0 0 0 !important;
}

.tagify__input {
  color: #000 !important;
}

.tagify {
  width: 100%;
  border: 1.5px solid #d1d5db !important;
  border-radius: 8px;
}

.tagify__tag.tagify--noAnim > div::before {
  animation: none;
  background-color: #f1f1f1 !important;
  color: #000;
  box-shadow: 0 0 0;
}

.tagify__tag > div::before {
  animation: none;
  background-color: #f1f1f1 !important;
  color: #000;
  box-shadow: 0 0 0 !important;
}

.dark
  .flex.items-stretch.w-full.h-full.text-sm.text-gray-500.border.border-gray-300.rounded.shadow-sm.ring-2.ring-blue-500.transition.duration-300.bg-white.hover\:border-gray-400 {
  background-color: #000;
  border: 1.5px solid lime;
  padding: 0px 5px;
  min-height: 43px;
  align-items: center;
  color: #fff;
  border-radius: 8px;
}

.dark .pl-1.text-gray-600 {
  background-color: var(--background-secondary);
  margin: 0px 5px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
}

.dark .w-full.pl-2\.5.py-2.pr-2.text-sm.flex.flex-wrap.gap-1 {
  padding: 5px 0;
}

.w-px.h-full.text-white.bg-gray-300.text-opacity-0 {
  display: none;
}

.dark .absolute.z-10.w-full.bg-white.shadow-lg.border.rounded.py-1.mt-1\.5.text-sm.text-gray-700 {
  background-color: #262626;
  border: none;
}

.dark .transition.duration-200.px-2.py-2.cursor-pointer.rounded.text-gray-500.hover\:bg-blue-100.hover\:text-blue-500 {
  color: #fff;
}

.flex.items-stretch.w-full.h-full.text-sm.text-gray-500.border.border-gray-300.rounded.shadow-sm.ring-2.ring-blue-500.transition.duration-300.bg-white.hover\:border-gray-400 {
  background-color: #fff;
  border: 1.5px solid #d1d5db;
  padding: 0px 5px;
  min-height: 43px;
  align-items: center;
  color: #000;
  border-radius: 8px;
}

.pl-1.text-gray-600 {
  background-color: #f1f1f1;
  margin: 0px 5px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
}

.w-full.pl-2\.5.py-2.pr-2.text-sm.flex.flex-wrap.gap-1 {
  padding: 5px 0;
}

.w-px.h-full.text-white.bg-gray-300.text-opacity-0 {
  display: none;
}

.absolute.z-10.w-full.bg-white.shadow-lg.border.rounded.py-1.mt-1\.5.text-sm.text-gray-700 {
  background-color: #f1f1f1;
  border: none;
}

.transition.duration-200.px-2.py-2.cursor-pointer.rounded.text-gray-500.hover\:bg-blue-100.hover\:text-blue-500 {
  color: #000;
}

.timeslot_check {
  background-color: #000;
  border-radius: 4px;
  overflow: auto;
  color: #fff;
}

.timeslot_check label {
  min-width: 4em;
  margin: 0;
}

.timeslot_check label input {
  position: absolute;
  top: -20px;
}

.timeslot_check input {
  visibility: hidden;
}

.timeslot_check label span {
  text-align: center;
  padding: 7px 10px;
  display: block;
  border-radius: 4px;
  font-size: 14px;
}

.timeslot_check input:checked + span {
  background-color: var(--background-secondary);
  color: #000;
}

.timeslot_check input {
  visibility: hidden;
}

.dark .kn-multi_select__wrapper___30BEc {
  background: #262626;
  border: 1px solid #262626;
  color: #ffffff;
}

.dark .kn-item__with_border___3n9sw {
  border-bottom: 1px solid var(--background-secondary) !important;
}

.dark input.kn-search__input___2tyxf:focus {
  border: 0 !important;
}

.dark .kn-search__search___3Zpb2 {
  border-bottom: 1px solid var(--background-secondary) !important;
}

.dark .kn-column__column___3Iwzx {
  border-right: 1px solid var(--background-secondary) !important;
}

.dark .kn-selection_status__selection_status___1FzyX {
  border-bottom: 1px solid var(--background-secondary) !important;
}

.dark .kn-selection_status__status___1qxE9 {
  color: #ffffff !important;
}

.dark .kn-selection_status__clear_all___3gppf {
  color: var(--background-secondary) !important;
}

.dark .kn-item__item___kQ2Ll span {
  color: #fff !important;
}

.dark .kn-item__selected___3tRL6 span {
  color: var(--background-secondary) !important;
}

.dark .kn-selected_item__selected_item___1uiiv:hover,
.dark .kn-selected_item__with_grouping___1Ibnd:hover {
  background-color: transparent !important;
}

.dark .kn-column__column___3Iwzx:last-of-type {
  border: 0 !important;
}

.dark .kn-item__item___kQ2Ll:hover {
  background-color: #262626 !important;
}

.dark .kn-item__selected___3tRL6 {
  background-color: transparent !important;
}

.dark .kn-selected_item__selected_item___1uiiv span {
  color: var(--background-secondary) !important;
}

.read-only {
  background-color: #d9d9d7;
  opacity: 0.5;
}

.active-tab {
  color: var(--text-secondary);
}

.link-tab-white {
  padding: 12px;
}

.link-tab-black {
  padding: 12px;
  color: var(--text-color-white);
  background: #22abe2;
}

.gm-style .gm-style-iw-c {
  background-color: #232733;
  color: #ffffff;
  padding-bottom: 10px !important;
}
.gm-style .gm-style-iw-tc::after {
  background: #232733;
}
.tooltip-hd {
  padding: 4px 0;
  font-weight: bold;
  font-size: 16px;
}
.tooltip_row {
  display: flex;
  padding: 5px 0;
}
.tooltip_row span {
  margin-right: 20px;
}
.tooltip_green {
  color: #2dbc09;
}
.tooltip_red {
  color: #f73339;
}
.gm-style-iw-d {
  overflow: hidden !important;
}
/* .gm-ui-hover-effect>span{-webkit-mask-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22/%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%3C/svg%3E); background-color: #fffff;} */

.gm-ui-hover-effect > span {
  background-color: #ffffff !important;
  width: 22px !important;
  height: 22px !important;
}
.gm-ui-hover-effect {
  top: -5px !important;
  right: 2px !important;
}
.agent_pic {
  width: 50px;
  border-radius: 50px;
}
